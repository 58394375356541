<template>
  <div class="Table mt-8">

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="desserts"
      :item-key="contentItem.id"
      :sort-by="['highlight','createTime']"
      :sort-desc="['true','true']"
      class="elevation-1 fav-table"
      :search="search"
      :loading="loading"
      show-select
      :page.sync="page"
      :items-per-page="itemsPerPage"
      v-resize="onResize"
      :hide-default-header="$vuetify.breakpoint.xs"
      hide-default-footer>
    >
    <!-- 途中ヘッダー -->
      <!-- <template v-slot:header="{  }" v-if="$vuetify.breakpoint.xs" >
        <tr>
          <th>
              <v-checkbox v-model="selected"  style="margin:0px;padding:0px" hide-details /> 
          </th>
          <th>
              <v-checkbox v-model="selected"  style="margin:0px;padding:0px" hide-details /> 
          </th>
        </tr>
      </template> -->


      <!-- トップ -->
      <template v-slot:top>
  
          <v-container >
            <v-row  :dense="$vuetify.breakpoint.xs" align="center">

              <v-col cols=4 sm=4 md=3 lg=3 xl=2 >
                <template >
                  <v-btn
                    color="narekomu"
                    dark
                    :width="($vuetify.breakpoint.xs)?'100%':'90%'"
                    @click="dialogUpload = true"
                    class="font-weight-bold mb-2 "
                  >
                    <v-icon :class="($vuetify.breakpoint.xs)?'':'mr-2'" >{{icons.trayArrowUp}}</v-icon>  
                    <span v-if="!$vuetify.breakpoint.xs" >アップロード</span>
                  </v-btn>
                </template>         
              </v-col>

              <v-spacer></v-spacer>
              <!-- 検索バー -->
              <v-col cols=11 sm=4 md=3 lg=3 xl=3 :order="($vuetify.breakpoint.xs)?'first':''">
                <v-text-field
                  v-model="search"
                  :append-icon="icons.magnify"
                  :class="(!$vuetify.breakpoint.xs) ?'mb-2':''"
                  label="Search"
                  single-line
                  hide-details
                
                ></v-text-field>
              </v-col>
              <!-- 使用容量 -->
              <v-col cols=8 sm=4 md=4 lg=3 xl=2 >
                <div :width="($vuetify.breakpoint.xs)?'100%':'90%'" :class="($vuetify.breakpoint.xs)?'mb-2':'mx-0 '">
                  <v-progress-linear
                    :value="usageRate"
                    rounded
                    :dense="$vuetify.breakpoint.xs"
                    disabled
                    height="35"
                    color="narekomu"
                    background-color="grey lighten-3"
                  >
                  <div  class="  grey--text text--darken-2">  {{ usageText }} </div>
                  </v-progress-linear>
                </div>
              </v-col>

            </v-row>
          
          </v-container>


      <v-expand-transition>
        <v-card
          v-show="expandSelected"
          elevation="0"
          height="70"
          width="100%"
          class="mx-auto"
          style="border-radius:0; background-color:rgb(29, 29, 29);;"
        >
          <div class="d-flex justify-start py-3" style="height:70px; width:100%;" >

              <div class="mx-5 mt-2" style="color:white;">{{selected.length}}件選択されました</div>
              <v-divider 
                vertical
                color="grey"
                class="mx-1"
              ></v-divider>
              <v-btn 
                color="white"
                class="mt-1 white--text"
                width="48%"
                text
                max-width="100px"
                :disabled="(selected.length==0)"
                @click="dialogDelete=true "
                >
                <p  class="my-auto"> 削除する </p>
              </v-btn>

              <v-btn 
                color="white"
                class="mt-1 white--text"
                width="48%"
                text
                max-width="100px"
                :disabled="(selected.length==0)"
                @click="batchDownload" 
                >
                <p  class="my-auto"> ダウンロード </p>
              </v-btn>

              <v-divider 
              ></v-divider>

              <v-btn 
                icon
                large
                color="white"
                class="mt-1 mr-3"
                @click="selectDelete()"
              >
                <v-icon >{{icons.close}}</v-icon>
              </v-btn>

            </div>
        
        </v-card>
      </v-expand-transition>
        <!-- </v-toolbar> -->
      </template>

      <template v-slot:body="{ items: item }">
           <v-hover v-slot="{ hover }" style="cursor:pointer;">
         <tr class="low-grey">
           <td >
              <v-checkbox v-model="selected" :value="item" style="margin:0px;padding:0px" hide-details />
          </td> 
          <!-- <td>
            <TableThumbnail
              :item="item"
              :idName="contentItem.id"
              :type="contentItem.type"
              :change="toggleChart"
              :soundId="soundId"
              v-on:changeThumbnailEmit="changeThumbnail"
              v-on:restoreThumbnailEmit="restoreThumbnail"
              v-on:showContentEmit="downloadContent"
              v-on:stopSoundEmit="stopSound"
            />
          </td> -->
          <td>
            <div class="ma-0 pa-0">
              <!-- 謎コード -->
              <p v-if="!hover" class="text-left pa-0 ma-0"  style="width:100%; height:100%">{{item.fileName}} </p>
              <div v-else class="d-flex pl-3 py-0" style="width:100%; height:100%">
             
              </div>
            </div>
          
         
          </td>
          <td>{{item.createTime}}</td>
          <td>{{item.fileSizeConvert}}</td>
         </tr>
        </v-hover>
       </template>

      <!-- サムネイル -->
      <template v-slot:item.thumbnail="{ item }" >
        <TableThumbnail
          :item="item"
          :idName="contentItem.id"
          :type="contentItem.type"
          :change="toggleChart"
          :soundId="soundId"
          v-on:changeThumbnailEmit="changeThumbnail"
          v-on:restoreThumbnailEmit="restoreThumbnail"
          v-on:showContentEmit="downloadContent"
          v-on:stopSoundEmit="stopSound"
         />

      </template>

      <!-- 名前 -->
      <template v-slot:item.fileName="{ item }" >
        <v-hover v-slot="{ hover }" style="cursor:pointer;">
          <div style="width:100%; height:100%">
            <v-row v-if="!hover | tablet" class="text-left ma-0 pa-0" align-content="center" style="width:100%; height:100%">
              
              {{item.fileName}} 
             
             
            </v-row>
            <div v-else class="d-flex pl-3" style="width:100%; height:100%">
              <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }"> 
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mr-4 my-auto"
                    icon

                    @click="renameItem(item)"
                  >
                    <v-icon> {{icons.pencil}}</v-icon>
                  </v-btn>
                  </template>
                  <span>名前の変更</span>

              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }"> 
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mr-4 my-auto"
                    icon
                    @click="deleteItem(item)"
                  >
                    <v-icon>{{icons.delete}}</v-icon>
                  </v-btn>
                </template>
                <span>アイテムの削除</span>
              </v-tooltip>

              <v-tooltip bottom v-if="contentItem.type=='url'">
                <template v-slot:activator="{ on, attrs }"> 
              
                  <v-btn 
                    v-bind="attrs"
                    v-on="on"
                    class="mr-4 my-auto"
                    icon
                    @click="copyClipBoard(item)"
                  >
                    <v-icon>{{icons.contentCopy}}</v-icon>
                  </v-btn>
                </template>
                <span>クリップボードにコピー</span>
              </v-tooltip>

              <v-tooltip bottom v-else >
                <template v-slot:activator="{ on, attrs }"> 
              
                  <v-btn 
                    v-bind="attrs"
                    v-on="on"
                    class="mr-4 my-auto"
                    icon
                    @click="downloadContent(item,false)"
                  >
                    <v-icon>{{icons.download}}</v-icon>
                  </v-btn>
                </template>
                <span>ダウンロード</span>
              </v-tooltip>

              <v-tooltip bottom  v-if="contentItem.type!='image' & contentItem.type!='sound' & contentItem.type!='url'">
                <template v-slot:activator="{ on, attrs }"> 
              
                  <v-btn 
                    v-bind="attrs"
                    v-on="on"
                    class="mr-4 my-auto"
                    icon
                    @click="changeThumbnail(item)"
                  >
                    <v-icon>{{icons.image}}</v-icon>
                  </v-btn>
                </template>
                <span>サムネイルの変更</span>
              </v-tooltip>

              
              <v-tooltip bottom v-if="contentItem.type!='navi' & contentItem.type!='image' & contentItem.type!='sound'& contentItem.type!='url' "  >
                <template v-slot:activator="{ on, attrs }"> 
              
                  <v-btn 
                    v-bind="attrs"
                    v-on="on"
                    class="mr-4 my-auto"
                    icon
                    @click="restoreThumbnail(item)"
                  >
                    <v-icon>{{icons.restore}}</v-icon>
                  </v-btn>
                </template>
                <span>サムネイルを元に戻す</span>
              </v-tooltip>

              <v-tooltip bottom v-if="contentItem.type!='navi' "  >
                <template v-slot:activator="{ on, attrs }"> 
                  <v-btn 
                    v-bind="attrs"
                    v-on="on"
                    class="mr-4 my-auto"
                    icon
                    @click="replaceContent(item)"
                  >
                    <v-icon>{{icons.fileReplace}}</v-icon>
                  </v-btn>
                </template>
                <span>コンテンツの差し替え</span>
              </v-tooltip>
                

            </div>
          </div>
        
        </v-hover>
      </template>
      <!-- アクション  タブレット時のみ--> 
      <template v-slot:item.actions="{ item }">

        <v-menu  v-if="tablet"
                  left  
                  offset-y 
                  :nudge-width="200"
                  >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn  
                    elevation=0
                    large
                    icon
                    v-bind="attrs"
                    :ripple="false"
                    v-on="on"
                    color="gray"
                  >
                    <v-icon  class="ma-0 pa-0">
                      {{icons.dotsHorizontalCircleOutline}}
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>

                    <v-list-item v-if="contentItem.type=='object'"
                    dense 
                    btn  
                    @click="downloadContent(item,true)">
                      <v-list-item-icon>
                        <v-icon>{{icons.video3d}}</v-icon>
          
                      </v-list-item-icon>
                    <v-list-item-title> 3Dモデルの表示</v-list-item-title>
                  </v-list-item>

                   <v-list-item v-if="contentItem.type=='pdf'"
                    dense 
                    btn  
                    @click="downloadContent(item,true)">
                      <v-list-item-icon>
                        <v-icon>{{icons.filePdfBox}}</v-icon>
                      
                      </v-list-item-icon>
                    <v-list-item-title> PDFの表示</v-list-item-title>
                  </v-list-item>

                  <v-list-item v-if="contentItem.type=='movie'"
                    dense 
                    btn  
                    @click="downloadContent(item,true)">
                      <v-list-item-icon>
                        <v-icon>{{icons.videoBox}}</v-icon>
                      
                      </v-list-item-icon>
                    <v-list-item-title> 動画の再生</v-list-item-title>
                  </v-list-item>

                  <v-list-item dense btn  @click="renameItem(item)">
                      <v-list-item-icon>
                        <v-icon>{{icons.pencil}}</v-icon>
                     
                      </v-list-item-icon>
                    <v-list-item-title> 名前の変更</v-list-item-title>
                  </v-list-item>

                  <v-list-item dense btn  @click="deleteItem(item)">
                      <v-list-item-icon>
                        <v-icon>{{icons.delete}}</v-icon>
                  
                      </v-list-item-icon>
                    <v-list-item-title> 削除</v-list-item-title>
                  </v-list-item>

                    <v-list-item dense btn  @click="downloadContent(item,false)">
                      <v-list-item-icon>
                        <v-icon>{{icons.download}}</v-icon>
                        
                      </v-list-item-icon>
                    <v-list-item-title> ダウンロード</v-list-item-title>
                  </v-list-item>

                  <v-list-item dense btn v-if="contentItem.type!='image' & contentItem.type!='url'" @click="changeThumbnail(item)">
                      <v-list-item-icon>
                        <v-icon>{{icons.image}}</v-icon>
                      
                      </v-list-item-icon>
                    <v-list-item-title > サムネイルの変更</v-list-item-title>
                  </v-list-item>

                   <v-list-item v-if =" contentItem.type!='navi' & contentItem.type!='image' & contentItem.type!='url' " dense btn  @click="restoreThumbnail(item)">
                      <v-list-item-icon>
                        <v-icon>{{icons.restore}}</v-icon>
              
                      </v-list-item-icon>
                    <v-list-item-title> サムネイルを元に戻す</v-list-item-title>
                  </v-list-item>

                  <v-list-item v-if="contentItem.type!='navi' & contentItem.type!='url' " dense btn  @click="replaceContent(item)">
                      <v-list-item-icon>
                        <v-icon> {{icons.fileReplace}}</v-icon>
              
                      </v-list-item-icon>
                    <v-list-item-title> コンテンツの差し替え</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
    
      </template>

      <template v-slot:item.url="{ item }">
       <a :href="item.url" style="word-break: break-all;"  target="_blank" rel="noopener noreferrer"> {{item.url}} </a>
      </template>

      <!-- フッター -->
      <template v-slot:footer>
        <!-- ページング -->
        <v-row class="text-center pt-2 align-center mx-2 mt-0" width="90%" wrap style="border-top:1px solid #bbbbbb;">
            <v-col class="text-truncate opacity-07 body-2 mb-0 pa-0" cols="12" md="12">
                 {{ pageText }} 
            </v-col>
            <v-col cols="12" md="12" class="mb-3 mt-0">
                <v-pagination
                    v-model="page"
                    color="narekomu"
                    class="mt-0"
                    @click="reChart"
                    :length="pageCount">
                </v-pagination>
            </v-col>
             </v-row>
      </template>

    
      <!-- スマホ テーブル表示 -->
      <template v-slot:body="{ items: item }" v-if="$vuetify.breakpoint.xs & contentItem.type!='url'">
        <v-progress-linear v-if="loading"
          indeterminate
          color="narekomu"
        ></v-progress-linear>
        <tbody>
          <tr v-for="i in item" :key="i[contentItem.id]"  >   
            <td class="ma-0 px-1 text-center">
              <v-checkbox v-model="selected" :value="i"  hide-details style="margin:0px;padding:0px" />
            </td> 
            <td class="ma-0 pa-0 pl-2 " min-width="160px" style="width:55vw;">
              <TableThumbnail
                :item="i"
                :idName="contentItem.id"
                :type="contentItem.type"
                :change="toggleChart"
                :changeSound="toggleChart"
                :soundId="soundId"
                v-on:changeThumbnailEmit="changeThumbnail"
                v-on:restoreThumbnailEmit="restoreThumbnail"
                v-on:showContentEmit="downloadContent"
                v-on:stopSoundEmit="stopSound"
              />
            </td>
            <td class="ml-2 mr-0 pr-0 " style="width:70%; word-wrap: break-word">
              <div class="d-flex flex-column">
                <div class="d-flex justify-space-between">
                 <p class="font-weight-bold mb-0 mt-1 overflow-wrap" style="word-break: break-all;" width="80%"> {{i.fileName}} </p>
                <v-menu 
                  left  
                  offset-y 
                  :nudge-width="200"
                  >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn  
                    elevation=0
                    small
                    icon
                    v-bind="attrs"
                    :ripple="false"
                    v-on="on"
                    dense 
                    color="gray"
                    class="mr-2"
                  >
                    <v-icon small class="ma-0 pa-0">
                      {{icons.dotsVertical}}
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>

                    <v-list-item v-if="contentItem.type=='object'"
                    dense 
                    btn  
                    @click="downloadContent(i,true)">
                      <v-list-item-icon>
                        <v-icon>{{icons.video3d}}</v-icon>
          
                      </v-list-item-icon>
                    <v-list-item-title> 3Dモデルの表示</v-list-item-title>
                  </v-list-item>

                   <v-list-item v-if="contentItem.type=='pdf'"
                    dense 
                    btn  
                    @click="downloadContent(i,true)">
                      <v-list-item-icon>
                        <v-icon>{{icons.filePdfBox}}</v-icon>
                      
                      </v-list-item-icon>
                    <v-list-item-title> PDFの表示</v-list-item-title>
                  </v-list-item>

                  <v-list-item v-if="contentItem.type=='movie'"
                    dense 
                    btn  
                    @click="downloadContent(i,true)">
                      <v-list-item-icon>
                        <v-icon>{{icons.videoBox}}</v-icon>
                      
                      </v-list-item-icon>
                    <v-list-item-title> 動画の再生</v-list-item-title>
                  </v-list-item>

                  <v-list-item dense btn  @click="renameItem(i)">
                      <v-list-item-icon>
                        <v-icon>{{icons.pencil}}</v-icon>
                     
                      </v-list-item-icon>
                    <v-list-item-title> 名前の変更</v-list-item-title>
                  </v-list-item>

                  <v-list-item dense btn  @click="deleteItem(i)">
                      <v-list-item-icon>
                        <v-icon>{{icons.delete}}</v-icon>
                  
                      </v-list-item-icon>
                    <v-list-item-title> 削除</v-list-item-title>
                  </v-list-item>

                    <v-list-item dense btn  @click="downloadContent(i,false)">
                      <v-list-item-icon>
                        <v-icon>{{icons.download}}</v-icon>
                        
                      </v-list-item-icon>
                    <v-list-item-title> ダウンロード</v-list-item-title>
                  </v-list-item>

                  <v-list-item dense btn v-if="contentItem.type!='image'" @click="changeThumbnail(i)">
                      <v-list-item-icon>
                        <v-icon>{{icons.image}}</v-icon>
                      
                      </v-list-item-icon>
                    <v-list-item-title > サムネイルの変更</v-list-item-title>
                  </v-list-item>

                   <v-list-item v-if=" contentItem.type!='image' " dense btn  @click="restoreThumbnail(i)">
                      <v-list-item-icon>
                        <v-icon>{{icons.restore}}</v-icon>
              
                      </v-list-item-icon>
                    <v-list-item-title> サムネイルを元に戻す</v-list-item-title>
                  </v-list-item>

                  <v-list-item  dense btn  @click="replaceContent(i)">
                      <v-list-item-icon>
                        <v-icon>  {{icons.fileReplace}}</v-icon>
              
                      </v-list-item-icon>
                    <v-list-item-title> コンテンツの差し替え</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
                <p class="caption mr-auto mt-0 mb- text--secondary"  > {{i.createTime}} </p>
                <p class="caption text--secondary align-end flex-column mb-0"> <span class="body-2 text--secondary ml-auto mb-0" >FileSize:</span> {{i.fileSizeConvert}}</p>
            
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <!-- スマホ テーブル表示 url -->
      <template v-slot:body="{ items: item }" v-else-if=" $vuetify.breakpoint.xs && contentItem.type=='url'">
        <tbody>
          <tr v-for="i in item" :key="i[contentItem.id]" >   
            <td >
              <v-checkbox v-model="selected" :value="i" style="margin:0px;padding:0px" hide-details />
            </td> 
            <td class="ml-2 mr-0 pr-0 pt-1 " style="width:100vw;" >
              <div class="d-flex flex-column" >
                <div class="d-flex" >
                  <p class="font-weight-bold mb-0 mt-1" style="width:60%; word-break: break-all;"> {{i.fileName}} </p>
                  <p class="caption mb-auto  text-end mt-1  text--secondary" style="width:40%;" > {{i.createTime}} </p>
                    <v-menu 
                    left  
                    offset-y 
                    :nudge-width="200"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn  v-if="contentItem.type!='image'"
                        elevation=0
                        small
                        icon
                        v-bind="attrs"
                        :ripple="false"
                        v-on="on"
                        dense 
                        color="gray"
                        class="mr-2"
                      >
                        <v-icon small class="ma-0 pa-0">
                          {{icons.dotsVertical}}
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>

                      <v-list-item dense btn  @click="renameItem(i)">
                          <v-list-item-icon>
                            <v-icon>{{icons.pencil}}</v-icon>
                        
                          </v-list-item-icon>
                        <v-list-item-title> 名前の変更</v-list-item-title>
                      </v-list-item>

                      <v-list-item dense btn  @click="deleteItem(i)">
                          <v-list-item-icon>
                            <v-icon>{{icons.delete}}</v-icon>
                      
                          </v-list-item-icon>
                        <v-list-item-title> 削除</v-list-item-title>
                      </v-list-item>
                      
                        <v-list-item dense btn  @click="copyClipBoard(i)">
                          <v-list-item-icon>
                            <v-icon> {{icons.contentCopy}}</v-icon>
                      
                          </v-list-item-icon>
                        <v-list-item-title> コピー</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
             
                <p class="caption text--secondary mt-1 align-end flex-column" style="width:90%;" > <a :href="i.url" style="color:#4ECDC4; word-break: break-all;" target="_blank" rel="noopener noreferrer"> {{i.url}} </a> </p>
            
              </div>
            </td>
          </tr>
        </tbody>
      </template>

  
    </v-data-table>
    
    <!-- ダイアログ -->
    <v-dialog 
      v-model="dialogUpload" 
      max-width="700px" 
      loading="false" 
      persistent           
      >
      <DialogUpload 
        v-on:uploadExeEmit="uploadExe"
        v-on:replaceExeEmit="replaceExe"
        v-on:flashErrorEmit="uploadError"
        v-on:uploadUrlExeEmit="uploadUrlExe"
        v-on:replaceUrlExeEmit="replaceUrlExe"  
        v-on:closeEmit="close"
        v-on:completeEmit="completeUpload"
        :contentItem="contentItem"
        :isReplace="isReplace"
        :replaceItem="targetItem"
        :dialog="dialogUpload"
      />
    </v-dialog>     


    <v-dialog v-model="dialog" max-width="700px" loading="false" persistent >
      <DialogRename 
        v-on:closeRenameEmit="close"
        v-on:renameItemExeEmit="renameItemExe"
        :name="renamedItem.fileName"
        :id="renamedItem.id"
        :desserts="desserts"
        :typeName="contentItem.title"
        :type="contentItem.type"

      />
    </v-dialog>


    <v-dialog v-model="dialogDelete" max-width="600px" loading="false" persistent>
      <DialogDelete 
        v-on:closeDeleteEmit="close"
        v-on:deleteItemExeEmit="deleteItemExe"
        :name="deletedItemName"
        :deleteList="selected"
        :typeId="contentItem.id"
        :type="contentItem.type"

      />
    </v-dialog>

    <v-dialog v-model="dialogChangeThumb" max-width="600px" persistent>
      <DialogThumbnailChange
          :thumbnail="targetThumbSrc"
          :id="targetThumbId"
          :type="contentItem.type"
          v-on:closeEmit="close"
          v-on:saveEmit="saveThumbnail"
      />
    </v-dialog>

    <v-dialog v-model="dialogRestoreThumb" max-width="300px" persistent>
      <DialogThumbnailRestore
          :id="targetThumbId"
          :type="contentItem.type"
          v-on:closeEmit="close"
          v-on:saveEmit="saveThumbnail"
      />
    </v-dialog>

    <v-dialog v-model="dialogImage" max-width="850px" width="90%"  :loading="loadingImage" persistent style="z-index:2001;">
      <DialogImageView
        :url="imageUrl" 
        :loading="loadingImage"
        :name="viewName"
        v-on:closeEmit="close"
      />
    </v-dialog>

      <v-dialog v-model="dialogPDF" max-width="700px" :loading="loadingPDF" persistent style="z-index:2001;">
      <DialogPDFView
        :url="pdfUrl" 
        :loading="loadingPDF"
        :name="viewName"
        v-on:closeEmit="close"
      />
    </v-dialog>

    <v-dialog v-model="dialogVideo" max-width="700px" :loading="loadingPDF" persistent style="z-index:2001;">
      <DialogVideoView
        :url="videoUrl" 
        :loading="loadingVideo"
        :name="viewName"
        v-on:closeEmit="close"
      />
    </v-dialog>

      <v-dialog v-model="dialog3D"   
      persistent 
      :height="($vuetify.breakpoint.sm || $vuetify.breakpoint.xs)?'100vh':'100vh'"  
      :width="($vuetify.breakpoint.sm || $vuetify.breakpoint.xs)?'100vw':'100vw'"
      :fullscreen="true"
      style="z-index:2001;"  >
      <DModel
        :modelUrl="modelUrl" 
        :loading="loading3D"
        :height="($vuetify.breakpoint.sm || $vuetify.breakpoint.xs)?'100vh':'100vh'"  
        v-on:closeEmit="close"
      />
    </v-dialog>

     <!-- url copy snackbar -->
    <v-snackbar
      v-model="snackbar"
    >
      クリップボードにコピーしました

      <template v-slot:action="{ attrs }">
        <v-btn
          color="narekomu"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- sound control snackbar -->
    <v-snackbar
      v-model="cardSound"
      color="white"
      :width="$vuetify.breakpoint.mdAndDown? '100%' :'70%'"
      max-width="2000px"
      timeout="-1"
      class="ma-0 pa-0"
      hide-details
      dense
    >
      <template>
        <v-container class="mt-2 mx-2 pa-0 ">
          <v-row align="center" width="100%">
            <v-col class="ma-0  pa-0 "  cols=10 sm=3 md=3 lg=3 xl=3 >
              <p  class="py-auto my-auto ml-4 black--text"> {{this.viewName}} </p>
            </v-col>
            <v-col class="ma-0 pa-0"  cols=12 sm=6 md=6 lg=6 xl=6 :order="($vuetify.breakpoint.xs)?'last':''" >
              <audio style="width:100%;" :src="soundUrl" controls controlslist="nodownload" id="audio-view"></audio>
            </v-col>
            <v-col class="ma-0 pr-4 py-0 text-end"  cols=1 sm=3 md=3 lg=3 xl=3  >
              <v-icon color="black" @click="stopSound">mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-snackbar>
   <!-- Flash Error -->
    <v-snackbar
        v-model="flashError"
        timeout="5000"
        transition="slide-x-reverse-transition"
        top
        color="red"
        class="mb-0 pb-0"
        :vertical="true"
      >
        <div class="  white--text text-right" style="width:100%;">
          <v-btn
            color="white"
            style="position:absolute ; right:5px; top:5px; "
            icon
            small
            dense
            @click="flashError = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="mx-4 mt-4 mb-0  white--text">
    
          以下のファイルのアップロード中にエラーが発生しました        
          <v-list v-for="(value, key, index) in errorFiles " :key="index"
            color="transparent"
          >・{{value}}</v-list>
          
        </div>
    </v-snackbar>

  </div>
</template>
  

<script>
import axios from "axios"
import { mdiClose, mdiContentCopy, mdiDelete, mdiDotsHorizontalCircleOutline, mdiDotsVertical, mdiDownload, mdiFilePdfBox, mdiFileReplace, mdiImage, mdiMagnify, mdiPencil, mdiRestore, mdiTrayArrowUp, mdiVideo3d, mdiVideoBox } from '@mdi/js';

const TableThumbnail = () => import('@/components/TableThumbnail.vue');
const DialogDelete = () => import('@/components/DialogDelete.vue');
const DialogRename = () => import('@/components/DialogRename.vue');
const DialogUpload = () => import('@/components/DialogUpload.vue');
const DialogPDFView = () => import('@/components/DialogPDFView.vue');
const DialogImageView = () => import('@/components/DialogImageView.vue');
const DialogVideoView = () => import('@/components/DialogVideoView.vue');
const DialogThumbnailChange = () => import('@/components/DialogThumbnailChange.vue');
const DialogThumbnailRestore = () => import('@/components/DialogThumbnailRestore.vue');
const DModel = () => import('@/components/3DModel.vue');



export default {
  name: 'ContentsTable',
  components :{
    TableThumbnail,
    DialogDelete,
    DialogRename,
    DialogUpload,
    DialogImageView,
    DialogPDFView,
    DialogVideoView,
    DModel,
    DialogThumbnailChange,
    DialogThumbnailRestore,
  },

  props:["contentItem"],
  data () {
    return {
      icons:{
        "trayArrowUp":mdiTrayArrowUp,
        "magnify":mdiMagnify,
        "close":mdiClose,
        "pencil":mdiPencil,
        "delete":mdiDelete,
        "contentCopy":mdiContentCopy,
        "download":mdiDownload,
        "image":mdiImage,
        "restore":mdiRestore,
        "fileReplace":mdiFileReplace,
        "dotsHorizontakCircleOutline":mdiDotsHorizontalCircleOutline,
        "video3d":mdiVideo3d,
        "filePdfBox":mdiFilePdfBox,
        "videoBox":mdiVideoBox,
        "dotsVertical":mdiDotsVertical,
      },
      request: null,
      items: null,
      selected:[],
      expandSelected:false,
      errorFiles:[],
      flashError:false,
      renameItemName:null,
      loading: true,
      loadingPDF:false,
      loadingImage:false,
      loadingVideo:false,
      loading3D:false,
      toggleChart:false,
      dialog: false,
      dialogUpload:false,
      dialogDelete: false,
      dialogImage:false,
      dialogPDF:false,
      dialogVideo:false,
      dialog3D:false,
      dialogChangeThumb:false,
      dialogRestoreThumb:false,
      targetItem:null,
      targetThumbSrc:null,
      targetThumbId:null,
      cardSound:false,
      soundUrl:null,
      soundId:null,
      usageRate:0,
      usageText:null,
      isReplace:false,
      videoUrl:null,
      imageUrl:null,
      pdfUrl:null,
      modelUrl:null,
      viewName:null,
      snackbar:false,
      search: "",
      contentSearch: this.$route.query.search,
      desserts: [],
      deletedItemName:null,
      tablet:false,
      totalPages:0,
      itemsPerPage:10,
      timer:0,
      page: 1,
      renamedItem: {
        name: '',
        id: '',
      },
    }
  },
  computed: {
    pageText(){
      return `${Math.max(1,(this.page-1)*this.itemsPerPage)} - ${Math.min(this.page*this.itemsPerPage,this.totalRecords)} of ${this.totalRecords} `
    },
     totalRecords() {
          return this.desserts.length
      },
    pageCount() {
      return Math.ceil(this.totalRecords / this.itemsPerPage)
    },
    topLoading: function(){
      return this.$store.state.user_model.topLoading;
    },
    isUser: function(){
      return this.$store.state.user_model.userData;
    },
    headers () {
     if(this.contentItem.type=="url"){
         return [
          {
            text: '名前',
            align: 'start',
            sortable: true,
            value: 'fileName',
            width: "30%",
          },
          {
            text: 'アップロード日',
            sortable: true,
            value: 'createTime',
            width: "15%",
          },
          {
            text: 'URL',
            sortable: true,
            value: 'url',
            width: "55%",
          },
          { text: '',
            sortable: false ,
            value: 'actions',
            width: "3%",
          },
        ]
      }
      else{
        return [
          {
            text:  this.contentItem.type=='sound'?'':'サムネイル',
            sortable: false,
            value: 'thumbnail',
            width: this.contentItem.type=='sound'?'2%':'10%',
            align: this.contentItem.type=='sound'?'center':'start'
          },
          {
            text: '名前',
            align: 'start',
            sortable: true,
            value: 'fileName',
            width: this.contentItem.type=='sound'?'45%':'30%',
          },
          {
            text: 'アップロード日',
            sortable: true,
            value: 'createTime',
            width: "25%",
          },
          {
            text: 'ファイルサイズ',
            sortable: true,
            value: 'fileSizeConvert',
            width: "30%",
          },
            { text: '',
            sortable: false ,
            value: 'actions',
            width: "3%",
          },
          // { text: 'ID',
          //   sortable: true ,
          //   value: `${this.contentItem.type}Id`,
          //   width: "20%",
          // },
        ]
      }
    },
  },




  async created() {
    if(!this.$store.state.user_model.topLoading){
      this.makeRate();
      await this.getData(false);
    }
    if (navigator.userAgent.indexOf('iPad') > 0 || navigator.userAgent.indexOf('Android') > 0) {
      this.tablet = true;
    }
  },

  watch: {
    contentItem: function(newVal,oldVal){
      if(oldVal.type=="sound"){
         this.stopSound();
      }
      this.makeRate();
      this.getData(false)
      this.search="";
      this.contentSearch = "";

    },

    selected: {
      handler(newVal){
        if(newVal.length){
          this.expandSelected = true;
        }
        else{
          this.expandSelected = false;
        }
      },
      deep: true,
    },

    topLoading: {
      handler(newVal){
        if(!newVal){
          this.getData(false);
        }
      },
      deep: true,
    },

    search: function(){
      setTimeout(this.reChart,100)
    },
    
    isUser:{
      handler(){
        this.makeRate();
      },
      deep: true,
    },

    show() {
      this.$nextTick(() => {
        this.$refs.sheet.showScroll();
      });
    },
    page:function(){
      setTimeout(this.reChart,100)
    }
    
  },

  methods: {
  
    //画面サイズが変更された場合にサムネイルの再描写
    onResize(){
      setTimeout(this.reChart,500);
    },

    getData: async function (reload) { 
      this.desserts = [];
      this.loading = true;
      const self = this;
      //読み込みを高速化するため少し遅延させる
      setTimeout(function(){
        let data = self.$store.state.user_model[self.contentItem.type];
        self.desserts = [];
        if(data != undefined  & data.length!=0  & !reload ){
          self.setupData(data);
        }else{
          self.$store.dispatch("setContentInfo",[self.contentItem.type,self.$store.state.auth.login]).then(() => {
          self.setupData(self.$store.state.user_model[self.contentItem.type]);
          }).catch(reason => {   
              console.log(reason)   
              self.loading = false;
        });
        }
      },10)

    },

    setupData(data){
      this.desserts = data;
      let isContentSearch = false;
      if(this.contentSearch!=""){
        for(let i = 0 ; i < this.desserts.length; i++){
          if(this.desserts[i].fileName == this.contentSearch){
            this.desserts[i].highlight = true;
            isContentSearch = true;
            break;
          }
        }
      }

      this.totalPage();     
      this.loading = false;
      this.request = axios.CancelToken.source();
      this.addReChart();
      
      setTimeout(this.reChart,100);
      if(isContentSearch){
        setTimeout(function(){
          let tr = document.getElementsByTagName("tbody")[0].getElementsByTagName("tr")[0];
          tr.style="background:aliceblue;"
        },300);
      }

    },

    //再描写の為のボタンを設置
    addReChart(){
      let elems = document.getElementsByClassName("v-list-item");
      for(let i = 0 ; i < elems.length; i++){
        elems[i].addEventListener('click',{handleEvent: this.reChart });
      }
      elems = document.getElementsByClassName("sortable");
      for(let i = 0 ; i < elems.length; i++){
        elems[i].addEventListener('click',{handleEvent: this.reChart });
      }
    },

    //サムネイル、オブジェクトの再描写
    reChart() {        
      this.toggleChart = !this.toggleChart;
    },

 
    totalPage(){
      this.totalPages = this.desserts.length;
    },
    //容量使用率の計算、表示
    makeRate(){
        if(this.$store.state.user_model.userData.userId){
          if(this.contentItem.type=="url"){
            let s = this.$store.state.user_model.userData['url'];
            let usage = s.usage;
            this.usageRate = 0;
            let unit ="個";
            this.usageText = ` ${usage} ${unit}`
          }else{
            let s = this.$store.state.user_model.userData.total;
            let times = (s.limit < 1) ? 1000:1;
            let usage = Math.round(s.usage*times*100)/100;
            this.usageRate = Math.round(s.usageRate*10)/10;
            let unit = (s.limit < 1) ? "MB" : "GB";
         
            this.usageText = ` ${usage} / ${s.limit*times} ${unit} (${this.usageRate} %) `
          }
        }
    },

      //名前変更ダイアログの表示と準備
    renameItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.renamedItem.fileName = item.fileName;
      this.renamedItem.id = item[this.contentItem.id]
      this.dialog = true
    },

    //名前変更の反映
    renameItemExe(newName){
      this.renamedItem.fileName = newName;
      if (this.editedIndex > -1) {
        this.desserts[this.editedIndex].fileName = this.renamedItem.fileName;
      } 
      this.close();
    },

    //テーブルへのアップロードされたアイテムの追加
    uploadExe(item){
      let fileSize =  item.fileSize
      let fileSizeConvert = (Number(fileSize)).toFixed(3) + " MB";
      let fileName = item.fileName.split(".")[0];
      let createTime = item.createTime;
      let thumbnail = item.thumbnail;
      let id = item.id;
      // 名前が重複している場合の処理
      let target = fileName;
      if(this.desserts.some(x => x.fileName == target )){
        let cnt = Math.max(this.desserts.filter(x => x.fileName.match(target+"\\(")).length,2);
        while(this.desserts.some(x => x.fileName == target ) && cnt < 1000  ){
          target =  `${fileName}(${cnt})` 
          cnt++;
        }
        fileName = target;
      }
      this.desserts.push({  
        thumbnail:thumbnail,
        fileSize:fileSize,
        fileSizeConvert:fileSizeConvert,
        fileName:fileName,
        createTime:createTime,
        [this.contentItem.id]:id})

    },


    //テーブルへURLの追加
    uploadUrlExe(item){
      let urlName = item.fileName
      let createTime = item.createTime;
      let url = item.url;
      let id = item.id;
      // 名前が重複している場合の処理
      let target = urlName;
      if(this.desserts.some(x => x.urlName == target )){
        let cnt = Math.max(this.desserts.filter(x => x.urlName.match(target+"\\(")).length,2);
        while(this.desserts.some(x => x.urlName == target ) && cnt < 1000  ){
          target =  `${urlName}(${cnt})` 
          cnt++;
        }
        urlName = target;
      }
      this.desserts.push({  
        url:url,
        fileName:urlName,
        createTime:createTime,
        [this.contentItem.id]:id,
        })

      this.close();
      this.reChart();
    },

    uploadError(files){
      this.flashError = true;
      this.errorFiles = files;
    },

    completeUpload(){
      setTimeout(this.reChart,3000);
    },

    //テーブルのコンテンツの差し替えを行う
    replaceExe(item,keepName){
      let fileSizeConvert = (Number(item.fileSize)).toFixed(3) + " MB";
      let fileName = item.fileName.split(".")[0];

      // 名前が重複している場合の処理
      let target = fileName;
      if(!keepName){
        if(this.desserts.some(x => x.fileName == target )){
          let cnt = Math.max(this.desserts.filter(x => x.fileName.match(target+"\\(")).length,2);
          while(this.desserts.some(x => x.fileName == target ) && cnt < 1000  ){
            target =  `${fileName}(${cnt})` 
            cnt++;
          }
        }
      }
      
       if (this.editedIndex > -1) {
         console.log(  this.desserts[this.editedIndex])
        this.desserts[this.editedIndex].fileName = target; 
        this.desserts[this.editedIndex].fileSize = item.fileSize;
        this.desserts[this.editedIndex].fileSizeConvert = fileSizeConvert;
        // this.desserts[this.editedIndex].createTime = item.createTime;
        this.desserts[this.editedIndex].thumbnail = item.thumbnail;
       }
       this.close();
    },

    //テーブルへURLの差し替え
    replaceUrlExe(item){
      let urlName = item.fileName
      // 名前が重複している場合の処理
      let target = urlName;
      if(this.desserts.some(x => x.urlName == target )){
        let cnt = Math.max(this.desserts.filter(x => x.urlName.match(target+"\\(")).length,2);
        while(this.desserts.some(x => x.urlName == target ) && cnt < 1000  ){
          target =  `${urlName}(${cnt})` 
          cnt++;
        }
      }
      console.log(this.editedIndex)
      if (this.editedIndex > -1) {
        this.desserts[this.editedIndex].fileName = target; 
        this.desserts[this.editedIndex].url = item.url;
       }

      this.close();
      this.reChart();
    },

    //コンテンツの差し替えダイアログの表示
    replaceContent(item){
      console.log(item)
      this.targetItem = item;
      this.editedIndex = this.desserts.indexOf(item);
      this.isReplace = true;
      this.dialogUpload = true;
    },

    //テーブルを選択できるボックスの表示
    selectDelete(){
      this.expandSelected = false;
      this.selected = [];
      // setTimeout(this.reChart,100)
    },

    //アイテム削除ダイアログの表示
    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.deletedItemName = item.fileName;
      this.selected.push(item);
      this.dialogDelete = true;
    },

    //テーブルのアイテムの削除の反映
    deleteItemExe(items){
      let id = null;
      Object.keys(items).forEach(key =>{
          id = this.desserts.indexOf(items[key])
          this.desserts.splice(id, 1)
      })
      this.close();
    },

    //一括ダウンロード
    batchDownload:async function(){
      for (let key in this.selected) {
        await this.downloadContent(this.selected[key],false)
      }
      this.selected = [];
    },

    //jsによるアイテムのダウンロード
    downloadContent:async function(item,show){
      this.soundId=(this.contentItem.type=="sound"&show) ? item.soundId : null;
      this.cardSound=(this.contentItem.type=="sound") ? show : false;
      this.dialogPDF =(this.contentItem.type=="pdf") ? show : false;
      this.loadingPDF= (this.contentItem.type=="pdf") ? show : false;
      this.dialogVideo =(this.contentItem.type=="movie") ?show : false;
      this.dialog3D =(this.contentItem.type=="object") ? show : false;
      this.dialogImage =(this.contentItem.type=="image") ? show : false;
      this.loading3D= (this.contentItem.type=="object") ? show : false;
      this.loadingImage= (this.contentItem.type=="image") ? show : false;
      this.viewName = item.fileName;
      await axios({
        method: "GET",
        url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/${this.contentItem.type}s/${item[this.contentItem.id]}`,
        headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId,"Content-Type":"application/json" },
      }).then((response) => {
        let s3Url = response.data.results;
        if(show && this.contentItem.type=="object") this.show3DModel(s3Url)
        else if(show && this.contentItem.type=="image") this.showImage(s3Url)
        else if(show && this.contentItem.type=="movie") this.showVideo(s3Url)
        else if(show && this.contentItem.type=="sound") this.playSound(s3Url)
        else if(show) this.showPDF(s3Url)
        else{
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.href = s3Url;
          a.download = item.fileName;
          a.click();
          a.remove();
        }


      }).catch(reason => {
        console.log(reason.response)
        this.isError = true;
      });
    },

    //サムネイル変更ダイアログの表示
    changeThumbnail(item){
      this.targetItem = item;
      this.targetThumbSrc = item.thumbnail;
      this.targetThumbId = item[this.contentItem.id];
      this.dialogChangeThumb = true;
    },

    //サムネイルを元に戻すダイアログの表示
    restoreThumbnail(item){
      this.targetItem = item;
      this.targetThumbId = item[this.contentItem.id];
      this.targetThumbSrc = item.thumbnail;
      this.dialogRestoreThumb = true;
    },

    //URLをクリップボードにコピー
    copyClipBoard(item){
      // console.log(item)
      const pre = document.createElement('pre');

      // テキストを選択可能にしてテキストセット
      pre.style.webkitUserSelect = 'auto';
      pre.style.userSelect = 'auto';
      pre.textContent = item.url;

      // 要素を追加、選択してクリップボードにコピー
      document.body.appendChild(pre);
      document.getSelection().selectAllChildren(pre);
      document.execCommand('copy');

      // 要素を削除
      document.body.removeChild(pre);
      this.snackbar = true;
    },

    //PDFダイアログの表示
    showPDF(url){
      axios({
      url: url,
      method: 'GET',
      responseType: 'blob'
    })
      .then(
        response => {
          let mimeType = 'application/pdf'
          let blob = new Blob([response.data], { type: mimeType })
          let k = window.URL.createObjectURL(blob);
          this.pdfUrl = k;
          if(this.$vuetify.breakpoint.xs) window.open(k);
          this.loadingPDF=false;
        })
      .catch(
        err => {
          this.loadingPDF=false;
          console.log(err)
        }
      )
    },

    //画像表示のダイアログの表示
    showImage(url){
      console.log(url)
      this.imageUrl = url;
      this.loadingImage=false;
    },


    //動画再生のダイアログの表示
    showVideo(url){
      this.videoUrl = url;
      this.loadingVideo=false;
    },

    //3Dモデルダイアログの表示
    show3DModel(url){
      this.modelUrl = url;
      this.loading3D = false;
      // console.log(url)
    },

    //mp3のURLの再生設定
    playSound(url){
      // console.log(url)
      // this.reChart();
      this.soundUrl = url;

      setTimeout(() => {
      let audio = document.getElementById("audio-view");
      // console.log(audio)
      if(audio){
        audio.currentTime = 0;
        audio.play();
      }
      }, 300);

    },

    //mp3の再生の中止
    stopSound(){
      this.soundId = null;
      let audio = document.getElementById("audio-view");
      // console.log(audio)
      if(audio){
        audio.pause();
      }
      this.cardSound=null;
    },


    //サムネイルの変更の反映
    saveThumbnail(img){
      let item = this.targetItem
      this.editedIndex = this.desserts.indexOf(item)
      item.thumbnail = img;
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], item)
      } 
      this.close();
    },

    close() {
      this.dialog = false;
      this.dialogDelete = false;
      this.dialogUpload = false;
      this.dialog3D = false;
      this.dialogImage = false;
      this.dialogPDF = false;
      this.dialogVideo = false;
      this.isReplace = false;
      this.imageUrl = null;
      this.dialogRestoreThumb = false;
      this.dialogChangeThumb = false;
      this.deletedItemName=null;
      this.targetItem = null,
      this.targetThumbSrc = null,
      this.targetThumbId = null,
      this.cardSound=null;
      this.selected = [];
      this.$nextTick(() => {
        this.editedIndex = -1
      })
      setTimeout(this.reChart,100)
    },
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color:#4ECDC4;
}

.text-start {
  padding: none;
}

v-data-table >>> div > table {
  border-spacing: 0 0.5rem;
}

.search-content{
  background-color: #42b983;
}


.v-snack__wrapper {
    max-width: none;
}

.v-snack__content {
    padding:2px !important;
}

.low-grey:hover {
  background-color: rgba(211, 211, 211, 0.15) !important;
}


audio::-webkit-media-controls-play-button,
    audio::-webkit-media-controls-panel {
    background-color:  white;
    color: rgb(142, 142, 142); 
    opacity: 0.8;
    }

    .v-data-footer {
        justify-content: center;
        padding-bottom: 8px;

        & .v-data-footer__select {
            margin: 0 auto;
        }

        & .v-data-footer__pagination {
            width: 100%;
            margin: 0;
        }
    }

    .h-0{
      height: 0px;
    }

    .h-50{
      height: 50px;
    }

    .v-application--is-ltr .v-data-footer__select .v-select {
        margin: 5px 0 5px 13px;
    }
    .v-application--is-rtl .v-data-footer__select .v-select {
        margin: 5px 13px 5px 0;
    }

    .v-input--selection-controls__ripple {
      margin:0  !important; ;
    }

    th {
      margin:0px !important;
    }


</style>
